<template>
    <div class="box_loading">
        <dv-loading >加载中...</dv-loading>
    </div>
</template>

<script>
export default {
    name: 'DASDINGSGCBigScree',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.box_loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #282c34;
    z-index: 999;
    opacity: 0.3;
}
</style>