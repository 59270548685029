<template>
  <div class="tag-title touch-enable" :class="{checked}" @click="$emit('onclick')">
    <span><slot></slot></span>
    <div class="block white b1"></div>
    <div class="block blue b2"></div>
    <div class="block white b3"></div>
    <div class="block blue b4"></div>
  </div>
</template>

<script>
export default {
  props:{
    checked:{
      type: Boolean,
      default:false
    }
  }
}
</script>

<style scoped lang="scss">
.tag-title{
  padding:0 px2vh(10);
  height: px2vh(32);
  background: rgba(68,143,255,0.05);
  border: px2vh(1) solid rgba(254,254,255,0.3);
  font-size: px2vh(12);
  color: rgba(255,255,255,0.7);
  line-height: px2vh(28);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover{
    cursor: pointer;
  }

  &.checked{
    background: rgba(68,143,255,0.2);
    box-shadow: inset 0 0 px2vh(10) 0 rgba(63,151,255,0.56);
    border-color: #4484FF;
    color: #FFFFFF;
  }

  .block{
    width: px2vh(4);
    height: px2vh(4);
    position:absolute;
    background: rgba(68,143,255,0.5);

    &.white{
      border: 1px solid #FFFFFF;
    }

    &.blue{
      border: 1px solid #448FFF;
    }

    &.b1{
      top:-1px;
      left:-1px;
    }

    &.b2{
      top:-1px;
      right:-1px;
    }

    &.b3{
      bottom:-1px;
      right:-1px;
    }

    &.b4{
      bottom:-1px;
      left:-1px;
    }
  }
}
</style>
