<template>
  <div class="right-group">
    <slot />
  </div>
</template>

<script>
export default {}
</script>
<style lang="scss">
.right-group {
  position: absolute;
  right: px2vh(28);;
  top: 0;
  width: px2vw(445);
  height: 100%;
  padding-top: px2vh(84);
  box-sizing: border-box;
   z-index: 2;
}
</style>
