<template>
  <div class="module-box">
    <module-title :title="title" :checked="checked" @click="$emit('click')">
      <slot slot="sub-title" name="sub-title"></slot>
      <slot slot="title-right" name="title-right"></slot>
    </module-title>
    <dv-border-box-8 :dur="10">
      <div class="module-container">
         <el-image  :src="decorateLeft" class="decorate-left" />
         <el-image  :src="decorateMiddle" class="decorate-middle" />
         <el-image  :src="decorateRight" class="decorate-right" />
        <slot></slot>
      </div>
    </dv-border-box-8>
  </div>
</template>

<script>
import ModuleTitle from "../module-title/module-title.vue";

export default{
  name: "module-box",
  props:{
    title:{
      type: String,
      default: ''
    },
    checked:{
      type:Boolean,
      default:true
    }
  },
  components: {
    ModuleTitle
  },
  data(){
    return {
      decorateLeft:require('@/assets/images/module-box/decorate-left.png'),
      decorateMiddle:require('@/assets/images/module-box/decorate-middle.png'),
      decorateRight:require('@/assets/images/module-box/decorate-right.png')
    }
  }

}
</script>

<style scoped lang="scss">
.module-box{
  background: rgba(7,24,50,0.15);
  .module-container{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .decorate-middle{
    position:absolute;
    right:2px;
    top:50%;
    display: block;
    transform: translateY(-50%);
  }
  .decorate-left{
    position:absolute;
    right:3px;
    bottom:3px;
    display: block;
  }
  .decorate-right{
    position:absolute;
    left:3px;
    bottom:3px;
    display: block;
  }
}
</style>
