<template>
  <div class="tc">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DASDINGSGCBigScreenTc",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.tc {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;

  z-index: 4;
}
</style>
