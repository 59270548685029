<template>
  <div class="map-iframe-wrap touch-enable">
    <iframe class="map-iframe touch-enable" @load="handleLoad" ref="iframe" v-if="gisMapUrl" :src="gisMapUrl" />
  </div>
</template>

<script>
export default {
  computed: {
    gisMapUrl() {
      return this.$store.get('gis-map-url');
    }
  },
  methods: {
    handleLoad() {
      this.$store.$emit('gis-loaded');
    },
    setGisOffset(dom, left, top) {
      const offset = {};
      const iframeOffset = $(this.$refs.iframe).offset();
      typeof left === 'number' && (offset.left = left + iframeOffset.left);
      typeof top === 'number' && (offset.top = top + iframeOffset.top);
      $(dom).offset(offset);
    }
  },
  mounted() {
    this.$store.getGisWindow = () => {
      return this.$refs.iframe.contentWindow;
    };
    //
    const _this = this;
    $.fn.extend({
      gisOffset: function (offset) {
        _this.setGisOffset(this, offset.left, offset.top);
      }
    });
  },
}
</script>
<style lang="scss" scoped>
.map-iframe-wrap {
  position: absolute;
  //left: px2vh(570);
  //right: px2vh(570);
  //top: px2vh(380);
  //bottom: px2vh(200);

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  //background-color: red;

  .map-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    overflow: hidden;
  }
}
</style>
