<template>
  <div class="box-body" :class="{show}">
    <div class="bgcont" :class="{show}" :style="{width:px2vwWidth}">
      <div class="box-title">
         <el-image  :src="titleBg"  class="box-title-bg"/>
        <div class="box-title-content">
          <span class="title">{{title}}</span>
          <slot name="title"></slot>
        </div>
      </div>
      <div class="box-content">
        <slot></slot>
      </div>
      <div class="close" @click.stop="close">
        <span class="el-icon-close"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width:{
      type:[String,Number],
      default:869
    },
    title:{
      type: String,
      default: ''
    },
    show:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {

    }
 },
  computed:{
    px2vwWidth(){
      return this.width / 1920 * 100 + 'vw'
    },
    titleBg(){
      if(this.width === 869) return require('@/assets/images/dialog/dialog-title.png')
      else if(this.width < 869) return require('@/assets/images/dialog/dialog-title-short.png')
      else if(this.width > 869) return require('@/assets/images/dialog/dialog-title-long.png')
    }
  },
  methods: {
    close() {
      this.$emit('update:show', false)
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.box-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999999;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;

  &.show{
    opacity: 1;
    visibility: visible;
  }
}
.bgcont {
  position: relative;
  transform: scale(.6);
  transition: all .3s ease-in-out;

  &.show{
    transform: scale(1);
  }

  .box-title{
    width:100%;
    height:px2vh(70);
    display: flex;
    align-items: center;

    .box-title-bg{
      position: absolute;
      width: 100%;
      display: block;
      z-index:1
    }

    .box-title-content{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: px2vw(60);
      position:relative;
      font-family: ysbth;
      z-index:2;
      box-sizing: border-box;

      .title{
        font-size: px2vh(34);
        letter-spacing: px2vh(6);
        text-shadow: 0 2px 2px rgba(0,38,95,0.3);
        background: linear-gradient(180deg, #FFFFFF 51%, #5AB0FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

    }

  }



  .box-content{
    width:100%;
    box-sizing: border-box;
    margin-top:px2vh(10);
    background: #021B32;
    box-shadow: inset -12px 0px 9px 0px rgba(5,34,81,0.68), inset 12px 0px 9px 0px rgba(5,34,81,0.68), inset 0px 12px 14px 0px rgba(48,106,186,0.64);
    border: 5px solid #336BB4;
    position: relative;

    &:after{
      content:'';
      display: block;
      position:absolute;
      height:5px;
      left:0;
      right:0;
      bottom:-5px;
      background:radial-gradient(circle, rgba(41, 207, 255, 1), rgba(163, 233, 253, 1), rgba(25, 123, 248, 1), rgba(25, 123, 248, 0));
    }

  }

  .close{
    width: px2vh(42);
    height: px2vh(42);
    border-radius: 50%;
    border:px2vh(2) solid #4AAEFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right:0;
    top:px2vh(-15);
    z-index:10;
    cursor: pointer;

    .el-icon-close{
      font-size:px2vh(30);
      color: #4AAEFF;
      font-weight:600;
    }

  }

}

</style>
