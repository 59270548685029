<template>
  <div class="module-title touch-enable">
    <div class="module-title-content">
      <div class="title-left">
        <span class="title" :class="{checked}" @click="$emit('click')">{{title}}</span>
        <slot name="sub-title"></slot>
      </div>
      <slot name="title-right"></slot>
    </div>
     <el-image  :src="titleBg" @mousemove.prevent.stop class="title-bg" />
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      default:''
    },
    type:{
      type:[Number,String],
      default:''
    },
    checked:{
      type:Boolean,
      default:true
    }
  },
  computed:{
    titleBg(){
      return require(`@/assets/images/module-box/hd${this.type}.png`)
    }
  }
}

</script>

<style scoped lang="scss">
.module-title{
  width:100%;
  height:px2vh(50);
  position:relative;
}
.title-bg{
  width: calc(100% - 4px);
  height:100%;
  position:absolute;
  top:2px;
  left:2px;
  z-index:1
}
.module-title-content{
  width: 100%;
  height:100%;
  position: relative;
  z-index:2;
  padding-left:px2vw(33);
  padding-bottom:px2vw(8);
  padding-right:px2vw(26);
  box-sizing:border-box;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.title-left{
  display: flex;
  align-items: flex-end;
  transform: translateY(px2vh(2));
}
.title{
  font-family: pmzdbtt;
  display: block;
  font-size: px2vh(20);
  color: rgba(255,255,255,0.7);

  &.checked{
    color: #FFFFFF;
    text-shadow: 0 0 px2vw(11) rgba(9,181,255,0.95);
    font-size: px2vw(26);
  }

  &:hover{
    cursor: pointer;
  }

}
</style>
