<template>
  <div class="normal-layout">
    <loading v-show="is_loading" />
    <div>
      <!-- <map-iframe /> -->
      <page-title />
      <header-component>
        <slot name="header" />
      </header-component>
      <middle-component>
        <slot name="middle"> </slot>
      </middle-component>
      <left-component>
        <slot name="left"></slot>
      </left-component>
      <right-component>
        <slot name="right"></slot>
      </right-component>
      <tc>
        <slot name="tc"></slot>
      </tc>
    </div>
  </div>
</template>

<script>
import pageTitle from "./pageTitle";
import headerComponent from "./header";
import leftComponent from "./left";
import rightComponent from "./right";
import mapIframe from "./mapIframe";
import middleComponent from "./middle/index.vue";
import loading from "@/components/loading/index.vue";
import store from "@/store";
import tc from "./tc.vue";

export default {
  components: {
    pageTitle,
    leftComponent,
    headerComponent,
    rightComponent,
    middleComponent,
    mapIframe,
    loading,
    tc,
  },
  computed: {
    is_loading() {
      return store.stores.get("is_loading");
    },
  },
};
</script>

<style lang="scss">
.normal-layout {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  // 禁止 normal-layout 内部所有dom元素的鼠标事件
  // 除非该元素拥有class touch-enable
  // 防止各种dom元素 挡住gis地图 影响地图的操作
  pointer-events: none;
  z-index: 0;
  @include background-image("~src/assets/images/bgnew.png");

  .touch-enable {
    pointer-events: auto;
  }
}
</style>
