<template>
  <div v-if="cont">
    <dialogs :title="title" :show="showDialog" @close="close">

      <div class="box_Sc" v-if="original_title" >
         <iframe id="iframeName" allowfullscreen='true'    allow="autoplay"  class="map-iframe touch-enable" :src="original_title" />
      </div>
      <div class="box_Sc" v-else v-html="cont"></div>
    </dialogs>
  </div>
</template>

<script>
import Dialogs from "@/components/dialogs";
export default {
  name: "DASDINGSGCBigScreenIndex",
  components: {
    Dialogs,
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    cont: {
      type: String,
      default: "",
    },
    original_title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },

  mounted() {},

  methods: {
    close() {
      this.$emit("update:showDialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.box_Sc {
  width: 100%;
  height: px2vh(593);
  padding: px2vw(30) px2vh(30);
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .map-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    overflow: hidden;
  }

  //font-size: px2vh(16);
  //font-weight: normal;
  //color: #ffffff;
  //line-height: px2vh(28);
}
.box_Sc::-webkit-scrollbar {
  width: px2vw(10);
  height: px2vh(10);
}
</style>
