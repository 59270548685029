<script>
const list = [];
export default {
  render(h) {
    return h();
  },
  computed: {
    storeTitle() {
      return this.$store.get('title');
    }
  },
  watch: {
    storeTitle: {
      immediate: true,
      handler() {
        this.setDocTitle();
      }
    }
  },
  methods: {
    setDocTitle() {
      document.title = this.storeTitle || window.pageTitle || '';
    },
    restoreDocTitle() {
      document.title = window.pageTitle || '';
    }
  },
  created() {
    list.push(this);
    this.setDocTitle();
  },
  beforeDestroy() {
    const index = list.indexOf(this);
    list.splice(index, 1);
    list.length === 0 && this.restoreDocTitle();
  }
}
</script>
